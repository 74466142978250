import styled from 'styled-components';

export const Resume = styled.section`

    .container {
        display: flex;
        flex-direction: column;

        @media (max-width: 1024px){

            padding-left: 2rem;
            padding-right: 2rem;

        }

        h2 {
            margin-bottom: 0.8rem;
            font-size: 2rem;

            &.top {
                padding-top: 2rem;
            }

            svg {
                color: ${ props => props.theme.darkGreyColor };
            }
        }

        .resume-element {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2rem;

            &.last {
                margin-bottom: 1rem;
            }

            .resume-content {
                display: flex;
                flex-basis: 48%;
                background-color: ${ props => props.theme.greyColor };
                margin-right: 1rem;
                margin-bottom: 1rem;
                padding: 1rem;

                .image-wrapper {
                    flex: 1;
                    margin-right: 0.8rem;

                    img {
                        width: 100%;
                    }
                }

                .content-block {
                    flex: 3;

                    h3 {
                        font-size: 1rem;
                        margin-bottom: 0.3rem;
                    }

                    p {
                        color: ${ props => props.theme.secondaryColor };
                        font-style: italic;
                        font-size: 0.7rem;
                    }

                    ul {
                        padding-top: 1rem;
                        list-style-type: circle;
                        margin-left: 1rem;

                        li {
                            font-size: 0.9rem;
                            padding-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px){

        .container {

            .resume-element {

                .resume-content {
                    flex-basis: 47%;
                }

            }

        }

    }

    @media (max-width: 500px){


        .container {

            padding: 1rem 1rem 0;

            h2 {
                font-size: 1.7rem;
            }

            .resume-element {
                flex-direction: column;

                .resume-content {
                    margin-right: 0;
                }
            }

        }

    }

`