import React from "react"
import Layout from './../components/shared/Layout';
import ResumeContent from "../components/resume/ResumeContent";

const ResumePage = () => (
  <Layout title="Resume">
      <ResumeContent />
  </Layout>
)

export default ResumePage;
