import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faBusinessTime, faGraduationCap, faCertificate } from '@fortawesome/free-solid-svg-icons';
import Header from './../shared/Header';
import TechStack from './../shared/TechStack';
import { Resume } from './../../elements/ResumeElements';

const ResumeContent = () => {

    const assets = useStaticQuery(graphql`
        query ResumeAssets {
            allContentfulAsset(filter: {
                title: {
                    eq: "resume"
                }
            }){
                edges {
                    node {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }
        }
    `)

    const pearson = assets.allContentfulAsset.edges.filter((edge) => edge.node.file.fileName.includes("pearson"));
    const arena = assets.allContentfulAsset.edges.filter((edge) => edge.node.file.fileName.includes("arena"));
    const tmg = assets.allContentfulAsset.edges.filter((edge) => edge.node.file.fileName.includes("times"));
    const resolution = assets.allContentfulAsset.edges.filter((edge) => edge.node.file.fileName.includes("resolution"));
    const tomorrow = assets.allContentfulAsset.edges.filter((edge) => edge.node.file.fileName.includes("tomorrow"));

    return (
        <Resume>
            <Header page="Prince Rathupa Resume" />
            <div className="container">
                <h2 className="top"><FontAwesomeIcon icon={faGraduationCap} /> Education</h2>
                <div className="resume-element">
                    <div className="resume-content">
                        <div className="image-wrapper">
                            <img src={pearson[0].node.file.url} alt="Pearson Institute" />
                        </div>
                        <div className="content-block">
                            <h3>BSc Computer Science - <span className="effect">Pearson Institute</span></h3>
                                <p><FontAwesomeIcon icon={faCalendarAlt} />  February 2014 - December 2016</p>
                                <ul>
                                    <li>Majored in Software Development, Computer Science and Information Systems</li>
                                    <li>Developed a web application for an NGO as a final year project</li>
                                </ul>
                        </div>
                    </div>
                </div>
                <h2><FontAwesomeIcon icon={faBusinessTime} /> Work <span className="effect">Experience</span></h2>
                <div className="resume-element">
                    <div className="resume-content">
                        <div className="image-wrapper">
                            <img src={arena[0].node.file.url} alt="Arena Holdings" />
                        </div>
                        <div className="content-block">
                            <h3>Junior Web Developer - <span className="effect">Arena Holdings</span></h3>
                            <p><FontAwesomeIcon icon={faCalendarAlt} />  February 2018 - Present</p>
                            <ul>
                                <li>Developing features and maintainining news and marketing websites.</li>
                                <li>Developing mail templates for news websites.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="resume-content">
                        <div className="image-wrapper">
                            <img src={tmg[0].node.file.url} alt="Times Media" />
                        </div>
                        <div className="content-block">
                            <h3>Developer Internship - <span className="effect">Times Media Group</span></h3>
                            <p><FontAwesomeIcon icon={faCalendarAlt} />  June 2017 - January 2018</p>
                            <ul>
                                <li>Developed features and maintained events and marketing websites.</li>
                                <li>Developed mail templates for events and marketing websites.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="resume-content">
                        <div className="image-wrapper">
                            <img src={resolution[0].node.file.url} alt="Resolution Circle"/>
                        </div>
                        <div className="content-block">
                        <h3>IT Internship - <span className="effect">Resolution Circle</span></h3>
                            <p><FontAwesomeIcon icon={faCalendarAlt} />  March 2017 - May 2017</p>
                            <ul>
                                <li>Integrated IoT techonolgies with the web interfaces.</li>
                                <li>Researched on IoT and Artificial Intelligence.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <h2><FontAwesomeIcon icon={faCertificate} /> Awards &amp; <span className="effect">Recognitions</span></h2>
                <div className="resume-element last">
                    <div className="resume-content">
                        <div className="image-wrapper">
                            <img src={tomorrow[0].node.file.url} alt="The Tomorrow Trust"/>
                        </div>
                        <div className="content-block">
                            <h3>Excellent Commitment - <span className="effect">The Tomorrow Trust</span></h3>
                            <p><FontAwesomeIcon icon={faCalendarAlt} /> November 2014</p>
                            <ul>
                                <li>Awarded for my excellent commitment to my tertiary education work.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <TechStack icon={true}/>
        </Resume>
    )

}

export default ResumeContent;